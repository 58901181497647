/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import "mixins";
@import "primeng/resources/themes/aura-light-blue/theme.css";


:root {
  --text-color: #334155;
  --text-color-secondary: #64748b;
  --primary-color: #3B82F6;
  --primary-color-text: #ffffff;

  --surface-0: #ffffff;
  --surface-50: #f8fafc;
  --surface-100: #f1f5f9;
  --surface-200: #e2e8f0;
  --surface-300: #cbd5e1;
  --surface-400: #94a3b8;
  --surface-500: #64748b;
  --surface-600: #475569;
  --surface-700: #334155;
  --surface-800: #1e293b;
  --surface-900: #0f172a;
  --surface-950: #020617;
  --gray-0: #ffffff;
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
  --gray-950: #020617;
  --content-padding: 1.125rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #f8fafc;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #e2e8f0;
  --surface-hover: #f1f5f9;
  --focus-ring: none;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #EFF6FF;
  --highlight-text-color: #3B82F6;
  --p-anchor-gutter: 2px;
  color-scheme: light;
}

